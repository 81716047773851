import React from 'react';
import clsx from 'clsx';
import { Typography } from '../../Typography';

export interface TitleProps extends React.ComponentPropsWithRef<'h2'> {}

export const Title: React.FC<TitleProps> = ({
  children,
  className,
  ...props
}) => (
  <header>
    <Typography
      id="frameadv__modal-heading"
      className={clsx('frameadv__modal-heading', className)}
      $variant="h2"
      $transform="uppercase"
      {...props}
    >
      {children}
    </Typography>
  </header>
);

import React from 'react';
import { Button } from '../Button';
import { Typography } from '../Typography';
import { Modal } from '../Modal';

export class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  closeModal() {
    window.location.reload();
  }
  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Modal
          showCloseButton={true}
          isOpen={hasError}
          onRequestClose={this.closeModal}
        >
          <Modal.Content>
            <Typography>
              Sorry, GENERIC ERRROR.
              <br /> Let's try again.
            </Typography>
          </Modal.Content>

          <Modal.Actions>
            <Button onClick={this.closeModal}>Close</Button>
          </Modal.Actions>
        </Modal>
      );
    }

    return children;
  }
}

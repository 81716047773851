import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';

export interface ContentProps extends React.ComponentPropsWithRef<'div'> {}

const StyledContent = styled.div<ContentProps>`
  font: ${({ theme: { fonts } }) => fonts.paragraph};
  color: ${({ theme: { colors } }) => colors.darkShade};
  line-height: 1.44;
  text-align: center;
`;

export const Content: React.FC<ContentProps> = ({
  children,
  className,
  ...props
}) => (
  <StyledContent
    id="frameadv__modal-description"
    className={clsx('frameadv__modal-description', className)}
    {...props}
  >
    {children}
  </StyledContent>
);

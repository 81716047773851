import React, { Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from './theme';
import { MemoryRouter, Switch, Route } from 'react-router';

import { ConfigParams } from './types/config';
import { I18nextProvider } from 'react-i18next';
import { useI18nInstance } from './i18n';
import { ErrorBoundary } from './components/ErrorBoundary';

import { ConfigContext } from './Context';
import { SessionProvider } from './Session';
import { Spinner } from './components/Spinner';

const Survey = React.lazy(() => import('./routes/Survey'));
const FaceScan = React.lazy(() => import('./routes/FaceScan'));
const Home = React.lazy(() => import('./routes/Home'));
const PLP = React.lazy(() => import('./routes/PLP'));

export interface FrameAdvisorProps {
  config: ConfigParams;
}

export const FrameAdvisor: React.FC<FrameAdvisorProps> = ({ config }) => {
  const i18nInstance = useI18nInstance(
    config.overrideTranslationsUrl,
    config.locale
  );

  return (
    <ThemeProvider theme={defaultTheme}>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <I18nextProvider i18n={i18nInstance}>
            <ConfigContext.Provider value={config}>
              <SessionProvider>
                <MemoryRouter>
                  <Switch>
                    <Route exact path={'/survey/:stepId?'} component={Survey} />
                    <Route exact path={'/facescan'} component={FaceScan} />
                    <Route exact path={'/plp'} component={PLP} />
                    <Route exact path={'/'} component={Home} />
                  </Switch>
                </MemoryRouter>
              </SessionProvider>
            </ConfigContext.Provider>
          </I18nextProvider>
        </Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

import { DefaultTheme } from 'styled-components';

const size = {
  huge: '1440px',
  large: '1170px',
  medium: '768px',
  small: '450px',
} as const;

const device = {
  small: `(min-width: ${size.small})`,
  medium: `(min-width: ${size.medium})`,
  large: `(min-width: ${size.large})`,
  huge: `(min-width: ${size.huge})`,
} as const;

const media = {
  small: `@media only screen and ${device.small}`,
  medium: `@media only screen and ${device.medium}`,
  large: `@media only screen and ${device.large}`,
  huge: `@media only screen and ${device.huge}`,
  tabletOnly: `@media only screen and ${device.small} and (max-width: ${size.large})`,
} as const;

declare module 'styled-components' {
  export interface DefaultTheme {
    fonts: {
      heading: string;
      paragraph: string;
      paragraphSmall: string;
      headingSmall: string;
    };
    colors: Record<
      | 'primary'
      | 'error'
      | 'disabled'
      | 'darkShade'
      | 'lightGrey'
      | 'primaryfg',
      string
    >;
    breakpoints: {
      size: typeof size;
      device: typeof device;
      media: typeof media;
    };
  }
}

export const defaultTheme: DefaultTheme = {
  fonts: {
    heading: 'normal normal 500 35px/normal sans-serif',
    headingSmall: 'normal normal 500 22px/normal sans-serif',
    paragraph: 'normal normal 200 16px/normal sans-serif',
    paragraphSmall: 'normal normal 200 12px/normal sans-serif',
  },
  colors: {
    primary: 'black',
    primaryfg: 'white',
    error: '#d0021b',
    disabled: '#f2f2f2',
    darkShade: '#202122',
    lightGrey: '#c6c7cc',
  },
  breakpoints: {
    size,
    device,
    media,
  },
};

export const isDevice =
  window.innerWidth <= Number(size.medium.substring(0, size.medium.length - 2));

import React from 'react';
import { Typography } from './Typography';

export const SubTitle: React.FC = ({ children }) => {
  return (
    <Typography $color="primaryfg" $variant="p" className="page-subtitle">
      {children}
    </Typography>
  );
};

import { useReducer, useEffect, createContext, useContext } from 'react';
import { useConfig } from './Context';
import { SessionData } from './types/session';
import { ConfigParams } from './types/config';

const SESSION_KEY = 'FA';

const setSessionData = (data: SessionData, config: ConfigParams) => {
  // set default
  if (!data.filters) {
    data.filters = {};
  }

  if (!data.filters?.mood) {
    data.filters.mood = config.defaultMood as string;
  }
  if (!data.filters?.productType) {
    data.filters.productType = config.defaultProductType as string;
  }

  if (config.saveSessionData) {
    try {
      config.saveSessionData(data);
    } catch (e) {
      console.error('error on saving session data', e);
    }
  } else {
    try {
      sessionStorage.setItem(SESSION_KEY, JSON.stringify(data));
    } catch (e) {
      console.error('error on saving session data', e);
    }
  }
};

const getSessionData = (config: ConfigParams): SessionData | undefined => {
  let data;

  if (config.getSessionData) {
    try {
      data = config.getSessionData();
    } catch (e) {
      console.error('error on getting session data', e);
    }
  } else {
    try {
      const localData = sessionStorage.getItem(SESSION_KEY) as string;
      if (localData) {
        data = JSON.parse(localData);
      }
    } catch (e) {
      console.error('error on getting session data', e);
    }
  }

  if (data) return data;
};

const sessionReducer = (_: any, data?: SessionData) => data;

export const SessionContext = createContext({} as any);
export const useSession = () => useContext(SessionContext);

export const SessionProvider: React.FC = ({ children }) => {
  const config = useConfig();

  const initialState = getSessionData(config);
  const [data, dispatch] = useReducer(sessionReducer, initialState);

  useEffect(() => {
    if (data) {
      data.updated = Date.now();
      setSessionData(data, config);
    }
  }, [data, config]);

  return (
    <SessionContext.Provider
      value={{
        data,
        dispatch,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

import React from 'react';
import styled from 'styled-components';

export interface ActionsProps extends React.ComponentPropsWithRef<'footer'> {}

const StyledFooter = styled.footer<ActionsProps>`
  display: flex;
  margin: 2.5rem 0;
  justify-content: center;
  button {
    margin: 0 1rem;
  }
`;

export const Actions: React.FC<ActionsProps> = ({ children, ...props }) => (
  <StyledFooter {...props}>{children}</StyledFooter>
);

import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import styled, { DefaultTheme } from 'styled-components';

export interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
  $color?: keyof DefaultTheme['colors'];
  $variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  $noMargin?: boolean;
  $align?: CSSProperties['textAlign'];
  $weight?: CSSProperties['fontWeight'];
  $transform?: CSSProperties['textTransform'];
}

export const Typography = styled.span.attrs<TypographyProps>(
  ({ className, $variant = 'p' }) => ({
    className: clsx('frameadv__typography', className),
    as: $variant,
  })
)<TypographyProps>`
  font: ${({ theme: { fonts }, $variant = 'p' }) =>
    ['p', 'span'].includes($variant) ? fonts.paragraph : fonts.heading};
  color: ${({ $color = 'darkShade', theme: { colors } }) => colors[$color]};
  margin: ${({ $noMargin = false }) => $noMargin && '0'};
  text-align: ${({ $align = 'center' }) => $align};
  font-weight: ${({ $weight }) => $weight};
  text-transform: ${({ $transform }) => $transform};
  white-space: pre-wrap;
`;

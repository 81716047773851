import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import styled, { createGlobalStyle, css } from 'styled-components';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import CloseIcon from '../../assets/icons/close.svg';
import { Title } from './Title';
import { Content } from './Content';
import { Actions } from './Actions';
import { transparentize } from 'polished';

export const ModalStyles = createGlobalStyle`
  .frameadv__modal-body-open {
    overflow: hidden;
  }
  .frameadv__modal {
    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${({ theme: { colors } }) =>
        transparentize(0.25, colors.darkShade)};
    }

    &-content {
      position: absolute;
      max-height: 92vh;
      max-width: 90vw;
      width: 492px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      overflow: auto;
      outline: none;
      padding: 10px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);


        ${({ theme }) => css`
          ${theme.breakpoints.media.large} {
            padding: 40px;
          }
        `}
    }
  }
`;

export interface ModalProps extends ReactModalProps {
  showCloseButton?: boolean;
  ['data-element-id']?: string;
}

const StyledCloseButton = styled.button`
  background-image: url('${CloseIcon}');
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const Modal: React.FC<ModalProps> & {
  Title: typeof Title;
  Content: typeof Content;
  Actions: typeof Actions;
} = ({
  children,
  showCloseButton = false,
  className,
  bodyOpenClassName = 'frameadv__modal-body-open',
  ...props
}) => {
  const [overlayRef, setOverlayRef] = useState<HTMLDivElement>();
  const dataElementId = props['data-element-id'];

  useEffect(() => {
    if (dataElementId && overlayRef) {
      overlayRef.setAttribute('data-element-id', dataElementId);
    }
  }, [overlayRef, dataElementId]);

  return (
    <>
      <ModalStyles />
      <ReactModal
        appElement={document.querySelector('.main-wrapper') as HTMLElement}
        ariaHideApp={process.env.NODE_ENV !== 'test'}
        {...props}
        bodyOpenClassName={bodyOpenClassName}
        className={{
          base: clsx('frameadv__modal-content', className),
          afterOpen: 'frameadv__modal-content--after-open',
          beforeClose: 'frameadv__modal-content--before-close',
        }}
        overlayClassName={{
          base: 'frameadv__modal-overlay',
          afterOpen: 'frameadv__modal-overlay--after-open',
          beforeClose: 'frameadv__modal-overlay--before-close',
        }}
        portalClassName="frameadv__modal"
        aria={{
          labelledby: 'frameadv__modal-heading',
          describedby: 'frameadv__modal-description',
        }}
        overlayRef={setOverlayRef}
      >
        {showCloseButton && (
          <StyledCloseButton onClick={props.onRequestClose} />
        )}
        {children}
      </ReactModal>
    </>
  );
};

Modal.Title = Title;
Modal.Content = Content;
Modal.Actions = Actions;

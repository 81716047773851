import React, { useEffect } from 'react';
import styled from 'styled-components';

import { fireAppLoading, fireAppLoaded } from '../../utils/events';

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Spinner: React.FC = (): JSX.Element => {
  useEffect(() => {
    fireAppLoading();
    return () => {
      fireAppLoaded();
    };
  }, []);

  return (
    <Wrapper className="spinner-wrapper">
      <div className="spinner-content">
        <div className="spinner-content--text">Loading...</div>
      </div>
    </Wrapper>
  );
};

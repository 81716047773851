import React from 'react';
import styled, { css } from 'styled-components';
import clsx from 'clsx';
import { Link, LinkProps } from 'react-router-dom';

export interface ButtonProps {
  $outlined?: boolean;
  $compact?: boolean;
}

const ButtonStyle = css<ButtonProps>(
  ({ $outlined, $compact, theme: { colors, fonts, breakpoints } }) => `
  box-sizing: border-box;
  color: ${$outlined ? colors.primary : 'white'};
  background-color: ${$outlined ? 'white' : colors.primary};
  border: solid 1.4px ${colors.primary};
  font: ${fonts.paragraph};
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  padding: 9px 26px;
  cursor: pointer;
  transition: box-shadow 0.25s ease-in-out;
  min-width: ${$compact ? '0' : '151px'};
  text-transform: uppercase;

  ${breakpoints.media.small} {
    min-width: 158px;
    padding: 9px 33px;
  }


  &:not(:disabled):hover,
  &:not(:disabled):focus {
    box-shadow: rgba(0, 0, 0, 0.6) 0px 4px 12px -5px;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${$outlined ? '#c6c7cc' : colors.disabled};
    border-color: ${colors.disabled};
    color: ${$outlined ? colors.disabled : '#c6c7cc'};
  }
`
);

const StyledButton = styled.button<ButtonProps>`
  ${ButtonStyle}
`;

const StyleLinkButton = styled(Link)<ButtonProps>`
  ${ButtonStyle}
  text-decoration: none;
`;

export const Button: React.FC<
  ButtonProps & React.ComponentPropsWithRef<'button'>
> = ({ children, className, ...props }) => (
  <StyledButton
    className={clsx(
      'frameadv__button',
      {
        'frameadv__button--outlined': props.$outlined,
      },
      className
    )}
    type="button"
    {...props}
  >
    {children}
  </StyledButton>
);

export const LinkButton: React.FC<ButtonProps & LinkProps> = ({
  children,
  className,
  ...props
}) => (
  <StyleLinkButton
    className={clsx(
      'frameadv__button',
      {
        'frameadv__button--outlined': props.$outlined,
      },
      className
    )}
    {...props}
  >
    {children}
  </StyleLinkButton>
);

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import HttpBackend from 'i18next-http-backend';
import Backend from './utils/I18NextMergeBackend';

import { Maybe } from './utils/types';
import { useEffect, useState } from 'react';

const getBackendConfig = (translationOverride?: Maybe<string>) => {
  const baseUrl =
    process.env.REACT_APP_BASE_URL &&
    process.env.REACT_APP_BASE_URL.endsWith('/')
      ? process.env.REACT_APP_BASE_URL
      : `${process.env.REACT_APP_BASE_URL}/`;

  const backendConfig = {
    backends: [HttpBackend],
    backendOptions: [
      {
        loadPath:
          process.env.REACT_APP_TRANSLATION_PATH ||
          `${baseUrl}/locales/{{lng}}/{{ns}}_{{lng}}.json`,
      },
    ],
    debounceInterval: 2000,
  };

  if (translationOverride) {
    backendConfig.backends.push(HttpBackend);
    backendConfig.backendOptions.push({
      loadPath: translationOverride,
    });
  }

  return backendConfig;
};

const getInitConfig = (
  translationOverride?: Maybe<string>,
  locale?: string
) => {
  return {
    ns: ['app-labels'],
    defaultNS: 'app-labels',
    lng: locale,
    fallbackLng: undefined,
    load: 'currentOnly' as any,
    // debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: getBackendConfig(translationOverride),
  };
};

const initI18n = (translationOverride?: Maybe<string>, locale?: string) => {
  const i18n = i18next.createInstance();
  i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init(getInitConfig(translationOverride, locale));

  return i18n;
};

const defaultI18nInstance = initI18n();

export const useI18nInstance = (
  translationOverride?: Maybe<string>,
  locale?: string
) => {
  const [i18nInstance, setI18nInstance] = useState(defaultI18nInstance);

  useEffect(() => {
    setI18nInstance(initI18n(translationOverride, locale));
  }, [translationOverride, locale]);

  return i18nInstance;
};

import React from 'react';
import { Typography } from './Typography';

export const Title: React.FC = ({ children }) => {
  return (
    <Typography $color="primaryfg" $variant="h2" className="page-title">
      {children}
    </Typography>
  );
};

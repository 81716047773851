import 'proxy-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { FrameAdvisor } from './FrameAdvisor';
import { Config } from './types/config';
import { GlassFilterConfig } from './types/filters';

class FrameAdvisorWidget {
  config: Config;

  constructor(config: Config) {
    this.config = config;

    if (!config.selector) {
      throw Error('selector configuration is empty');
    }

    if (!config.locale) {
      throw Error('locale configuration is empty');
    }

    if (!config.facescanPrivacy) {
      throw Error('facescanPrivacy configuration is empty');
    } else {
      if (!config.facescanPrivacy.privacyPolicy) {
        throw Error('facescanPrivacy.privacyPolicy configuration is empty');
      }
      if (!config.facescanPrivacy.termsAndConditions) {
        throw Error(
          'facescanPrivacy.termsAndConditions configuration is empty'
        );
      }
      // config.facescanPrivacy.localizationText is not mandatory
    }

    if (!config.resultCallback) {
      throw Error('resultCallback configuration is empty');
    }

    if (!config.facescanSource) {
      throw Error('facescanSource configuration is empty');
    }
    if (!config.facescanRegion) {
      throw Error('facescanRegion configuration is empty');
    }
    if (!config.frameAdvAPICore) {
      throw Error('frameAdvAPICore configuration is empty');
    }
    if (!config.frameAdvAPIStore) {
      throw Error('facescanSource configuration is empty');
    }

    // set default values
    if (!this.config.defaultMood) {
      this.config.defaultMood = 'harmony';
    }
    if (!this.config.defaultProductType) {
      this.config.defaultProductType = 'sunglass';
    }
    if (!this.config.glassFilters) {
      this.config.glassFilters = [
        'productType',
        'gender',
        'mood',
      ] as GlassFilterConfig;
    }
    if (!this.config.productTypes) {
      this.config.productTypes = ['sunglass', 'eyeglass'];
    }
    if (!this.config.productRequestRowsLimit) {
      this.config.productRequestRowsLimit = 500;
    }
  }

  async render() {
    const { selector } = this.config;

    const container = document.querySelector(selector);

    if (container) {
      ReactDOM.render(
        <React.StrictMode>
          <FrameAdvisor config={this.config} />
        </React.StrictMode>,
        container
      );
    }
  }

  static new(config: Config) {
    return new FrameAdvisorWidget(config);
  }
}

export { FrameAdvisor, FrameAdvisorWidget };

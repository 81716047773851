const showFacescanApp = new Event('fa-show-facescanapp');
const showSurvey = new Event('fa-show-survey');
const loadProducts = new Event('fa-load-products');
const loading = new Event('fa-loading');
const loaded = new Event('fa-loaded');
const productsLoading = new Event('fa-products-loading');
const productsLoaded = new Event('fa-products-loaded');

export const fireShowFacescanApp = () => {
  const body = document.querySelector('body');

  if (body) {
    body.dispatchEvent(showFacescanApp);
  }
};

export const fireShowSurvey = () => {
  const body = document.querySelector('body');
  if (body) {
    body.dispatchEvent(showSurvey);
  }
};

export const fireLoadProducts = () => {
  const body = document.querySelector('body');
  if (body) {
    body.dispatchEvent(loadProducts);
  }
};

export const fireProductsLoading = () => {
  const body = document.querySelector('body');
  if (body) {
    body.dispatchEvent(productsLoading);
  }
};

export const fireProductsLoaded = () => {
  const body = document.querySelector('body');
  if (body) {
    body.dispatchEvent(productsLoaded);
  }
};

export const fireAppLoading = () => {
  const body = document.querySelector('body');
  if (body) {
    body.dispatchEvent(loading);
  }
};

export const fireAppLoaded = () => {
  const body = document.querySelector('body');
  if (body) {
    body.dispatchEvent(loaded);
  }
};
